import React from 'react'
import './Initials.scss'

export default function Initials({ isSidebarExpanded }) {

    const widthScreen = window.innerWidth;

    let isExpanded = null;
    if (widthScreen > 615) {
        isExpanded = isSidebarExpanded;
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 549.36 333.37" className={`animate-initials-svg ${isExpanded ? 'sidebar-expanded' : 'sidebar-retracted'}`}>
            <defs>
                {/* Definimos el gradiente que simulará el haz de luz */}
                <linearGradient id="lightGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="rgba(255, 255, 255, 0)" />
                    <stop offset="50%" stopColor="#8123b8" />
                    <stop offset="100%" stopColor="rgba(255, 255, 255, 0)" />
                </linearGradient>
            </defs>
            <g id="Capa_2" data-name="Capa 2">
                <g id="Prueba">
                    {/* Las líneas del SVG tendrán el pulso sin interferencias */}
                    {/* Letra A */}
                    <polygon className="cls-1 pulse" points="129.14 19 113.63 19 0.41 241.88 49.04 241.88 123.11 93.42 157.15 162.27 111.83 162.6 96.09 200.93 176.28 200 196.06 241.88 240.71 242.63" />
                    {/* Letra V */}
                    <polygon className="cls-1 pulse" points="119.79 0.25 179.26 0.25 266.85 179.26 351.03 0.25 412.43 0.25 383.45 58.27 342.29 58.27 342.23 140.81 282.38 260.63 249.69 260.63 119.79 0.25" />
                    {/* Letra J */}
                    <path className="cls-1 pulse" d="M342.29,58.27h53.62V233.36h0l0,22.77c0,10.86-2.3,27.58-5,32.77-37.81,75.19-99,31.73-99,31.73V274.44c42.67,18.8,48.41-8.27,49.12-13s1.15-15.62,1.15-15.62Z" />
                    {/* Letra D */}
                    <path className="cls-1 pulse" d="M395.91,241.88l43.46.75c138.21.1,154.22-199.41,0-224.38l-36.19.51m-7.27,39.51,40.2.37c87.88,9.55,91.32,139.08,0,140.61H395.92" />

                    {/* Haz de luz aplicado de manera secuencial en cada elemento */}
                    {/* Letra A */}
                    <polygon className="cls-1 light-trail delayed-light-1" points="129.14 19 113.63 19 0.41 241.88 49.04 241.88 123.11 93.42 157.15 162.27 111.83 162.6 96.09 200.93 176.28 200 196.06 241.88 240.71 242.63" />
                    {/* Letra V */}
                    <polygon className="cls-1 light-trail delayed-light-2" points="119.79 0.25 179.26 0.25 266.85 179.26 351.03 0.25 412.43 0.25 383.45 58.27 342.29 58.27 342.23 140.81 282.38 260.63 249.69 260.63 119.79 0.25" />
                    {/* Letra J */}
                    <path className="cls-1 light-trail delayed-light-3" d="M342.29,58.27h53.62V233.36h0l0,22.77c0,10.86-2.3,27.58-5,32.77-37.81,75.19-99,31.73-99,31.73V274.44c42.67,18.8,48.41-8.27,49.12-13s1.15-15.62,1.15-15.62Z" />
                    {/* Letra D */}
                    <path className="cls-1 light-trail delayed-light-4" d="M395.91,241.88l43.46.75c138.21.1,154.22-199.41,0-224.38l-36.19.51m-7.27,39.51,40.2.37c87.88,9.55,91.32,139.08,0,140.61H395.92" />
                </g>
            </g>
        </svg>
    )
}
