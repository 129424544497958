import React from 'react'
import './CardCompetencies.scss'
import ProgressBar from '../../progress_bar/ProgressBar'

export default function CardCompetencies({ isSidebarExpanded, title, data }) {
    return (
        <div className='content_competencies'>
            <h2 className='subtitle'>{title}</h2>

            <div className={`competenicies ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
                {data.map((competency, index) => (
                    <div key={index} className='competency'>
                        <div className='icon'>
                            <i className={`fa-solid ${competency.icon}`}></i>
                            <div className='text'>
                                <span className='title'>{competency.title}</span>
                                <span className='description'>{competency.description}</span>
                            </div>
                        </div>
                        <ProgressBar name="Skill" percentage={competency.percentage} color='8123b8EE' />
                    </div>
                ))}
            </div>
        </div>
    )
}
