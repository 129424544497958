// Datos de la educacion
export const educations = [
    {
        title: 'Primary school - Educational Unit "Ernesto Alban Mosquera"',
        date: '2005 - 2011',
        sections: [
            { text: 'I attended elementary school from the age of 4 to 10 years old. During these years, I acquired fundamental skills in reading, writing and mathematics, as well as learned the values of discipline, teamwork and responsibility.' }
        ]
    },
    {
        title: 'High School - Educational Unit "Ernesto Alban Mosquera"',
        date: '2012 - 2018',
        sections: [
            { text: 'I continued my secondary education at the same school, where I obtained a bachelor\'s degree in General Science. During this time, I had the opportunity to explore my interests and abilities in a variety of areas.' }
        ]
    },
    {
        title: 'University of the Armed Forces "ESPE"',
        date: '2019 - 2024 (Graduation process)',
        sections: [
            { text: 'During these years, I have had the opportunity to deepen my knowledge in software development, participating in specialized courses and working on research projects. This has allowed me to expand my knowledge in emerging technologies, improve my skills in complex problem solving and collaborate in the design and implementation of efficient solutions in programming environments.' }
        ]
    },
];

// Datos de la experiencia laboral
export const experiences = [
    {
        title: 'Administrative Assistant',
        date: '2018 - 2018',
        sections: [
            { subtitle: 'Administrative Assistant in the General Secretariat Area, "Educational Unit Iberoamericano".', text: 'Activities: Data entry in the system.' }
        ]
    },
    {
        title: 'Hardware and software technician',
        date: '2021 - 2021',
        sections: [
            { subtitle: 'Technician in computer equipment and web pages,  HINGELECTC CIA. LTDA.', text: 'Activities: Equipment maintenance, web site maintenance and template design.' }
        ]
    },
    {
        title: 'Specialist in technology, marketing and graphic design',
        date: '2021 - 2023',
        sections: [
            { subtitle: 'Marketing and graphic design, SCIEDTEC.', text: 'Activities: Advertising in social networks, Video creation, Graphic design and logo vectorization.' },
            { subtitle: 'Web developer, SCIEDTEC.', text: 'Activities: Main website creation, system maintenance, database administrator, equipment maintenance and social media specialist.' }
        ]
    },
    {
        title: 'Creative Publicist',
        date: '2023 - 2023',
        sections: [
            { subtitle: 'Publicist, HINGELECTC CIA. LTDA.', text: 'Activities: Advertising in social networks (videos and posts).' }
        ]
    },
    {
        title: 'Web development',
        date: '2023 - 2024',
        sections: [
            { subtitle: 'Programmer, DRINKING WATER AND SANITATION ADMINISTRATIVE BOARD.', text: 'Activities: Analysis, design and implementation of the computer module.' }
        ]
    },
    {
        title: 'Web development (THESIS)',
        date: '2024 - 2024',
        sections: [
            { subtitle: 'Web application for the analysis and prediction of the academic performance of the Information Technology career of the ESPE Santo Domingo Campus.', text: 'Activities: Data analysis and processing, Web development (Back-End/Front-End), Web design (UI/UX), Predictive models and algorithms (Artificial Intelligence).' }
        ]
    },
    {
        title: 'Freelancer',
        date: '2024 - Currently',
        sections: [
            { subtitle: 'Web developer.', text: 'Activities: Web design and development.' }
        ]
    },
];

const urlWorkCertificate = 'assets/docs/certificate/employee/';
const urlImgWork = 'assets/img/certificate/employee/';

// Datos de los certificados de trabajo
export const workCertifications = [
    {
        title: 'Administrative Assistant in the Area of General Secretary',
        institution: 'Iberoamericano',
        urlView: `${urlWorkCertificate}Certificado Iberoamericano.pdf`,
        urlDownload: `${urlWorkCertificate}Certificado Iberoamericano.pdf`,
        img: `${urlImgWork}Certificado Iberoamericano.jpg`,
    },
    {
        title: 'Maintaining  computer equipment and web pages',
        institution: 'HINGELECTC',
        urlView: `${urlWorkCertificate}Certificado HTC.pdf`,
        urlDownload: `${urlWorkCertificate}Certificado HTC.pdf`,
        img: `${urlImgWork}Certificado HTC.jpg`,
    },
    {
        title: 'Sufficiency of the English B1 languague of the European Common Framework',
        institution: 'ESPE',
        urlView: `${urlWorkCertificate}CERTIFICACION Ingles B1.pdf`,
        urlDownload: `${urlWorkCertificate}CERTIFICACION Ingles B1.pdf`,
        img: `${urlImgWork}CERTIFICACION Ingles B1.jpg`,
    },
    {
        title: 'Implementation of the web and mobile applications to manage community emergencies',
        institution: 'ESPE',
        urlView: `${urlWorkCertificate}Certificado Vinculación.pdf`,
        urlDownload: `${urlWorkCertificate}Certificado Vinculación.pdf`,
        img: `${urlImgWork}Certificado Vinculación.jpg`,
    },
    {
        title: 'Pre-proffessional practices 1 as a systems assistant',
        institution: 'HINGELECTC',
        urlView: `${urlWorkCertificate}Cerfificado de practicas - HTC.pdf`,
        urlDownload: `${urlWorkCertificate}Cerfificado de practicas - HTC.pdf`,
        img: `${urlImgWork}Cerfificado de practicas - HTC.jpg`,
    },
    {
        title: 'Pre-proffessional practices 2 as data analysis and processing',
        institution: 'ESPE',
        urlView: `${urlWorkCertificate}Certificado de practicas.pdf`,
        urlDownload: `${urlWorkCertificate}Certificado de practicas.pdf`,
        img: `${urlImgWork}Certificado de practicas.jpg`,
    },
    {
        title: 'Marketing: Network advertising and graphic design',
        institution: 'SCIEDTEC',
        urlView: `${urlWorkCertificate}Certificados Sciedtec-2.pdf`,
        urlDownload: `${urlWorkCertificate}Certificados Sciedtec-2.pdf`,
        img: `${urlImgWork}Certificados Sciedtec-2.jpg`,
    },
    {
        title: 'Web developer: Creation and maintenance of the main website',
        institution: 'SCIEDTEC',
        urlView: `${urlWorkCertificate}Certificados Sciedtec-1.pdf`,
        urlDownload: `${urlWorkCertificate}Certificados Sciedtec-1.pdf`,
        img: `${urlImgWork}Certificados Sciedtec-1.jpg`,
    },
    {
        title: 'Web developer: Analysis, Design and Implementation of a computer module',
        institution: 'Water Gasket',
        urlView: `${urlWorkCertificate}Certificado Junta de agua.pdf`,
        urlDownload: `${urlWorkCertificate}Certificado Junta de agua.pdf`,
        img: `${urlImgWork}Certificado Junta de agua.jpg`,
    },
];

const urlCertificate = 'assets/docs/certificate/completion/';
const urlImg = 'assets/img/certificate/completion/';

// Datos de los certificados de cursos
export const certifications = [
    {
        title: 'Database Administrator',
        institution: 'Capacítate para el empleo',
        url: 'https://capacitateparaelempleo.org/verifica/178d5c98-9285-4936-b170-c6936d0459e1/7e0c02f7-1438-496c-bfc3-cb6321136a0a',
        urlDownload: `${urlCertificate}Administrador de BDD.pdf`,
        img: `${urlImg}Administrador de BDD.jpg`,
    },
    {
        title: 'Server Administrator',
        institution: 'Capacítate para el empleo',
        url: 'https://capacitateparaelempleo.org/verifica/484a7a4e-6247-4c47-8d87-ac486f15f0b8/5c3368b2-a26e-4f25-a749-19f605222799',
        urlDownload: `${urlCertificate}Administrador de servidores.pdf`,
        img: `${urlImg}Administrador de servidores.jpg`,
    },
    {
        title: 'Inspiring study conference with Google',
        institution: 'Progressio',
        url: 'hhttps://www.progresionate.com/valorlegaldelcertificadoprogressiolatam',
        urlDownload: `${urlCertificate}Conferencia GOOGLE.pdf`,
        img: `${urlImg}Conferencia GOOGLE.jpg`,
    },
    {
        title: 'Programmer (object oriented)',
        institution: 'Capacítate para el empleo',
        url: 'https://capacitateparaelempleo.org/verifica/178d5c98-9285-4936-b170-c6936d0459e1/21159bf3-048b-4a05-b323-23ce209e2625',
        urlDownload: `${urlCertificate}POO.pdf`,
        img: `${urlImg}POO.jpg`,
    },
    {
        title: 'Finder',
        institution: 'Capacítate para el empleo',
        url: 'https://capacitateparaelempleo.org/verifica/178d5c98-9285-4936-b170-c6936d0459e1/4b780846-d302-47f1-9b56-7ebec021e33c',
        urlDownload: `${urlCertificate}Finder.pdf`,
        img: `${urlImg}Finder.jpg`,
    },
    {
        title: 'Angular from zero to expert',
        institution: 'DevTalles',
        url: 'https://cursos.devtalles.com/certificates/3gy1ydcbwf',
        urlDownload: `${urlCertificate}Angular cero experto.pdf`,
        img: `${urlImg}Angular cero experto.jpg`,
    },
    {
        title: 'DevOps on AWS: Code, Build, and Test',
        institution: 'Courcera',
        url: 'https://www.coursera.org/account/accomplishments/verify/AJRWAXSNVA5U',
        urlDownload: `${urlCertificate}AWS DevOps.pdf`,
        img: `${urlImg}AWS DevOps.jpg`,
    },
    {
        title: 'Artificial Intelligence and Deep Learning from scratch in Python',
        institution: 'Údemy',
        url: 'https://www.udemy.com/certificate/UC-323f439f-9d58-41d7-8794-1e99144aec29/',
        urlDownload: `${urlCertificate}IA y Deep Learning desde cero en python.pdf`,
        img: `${urlImg}IA y Deep Learning desde cero en python.jpg`,
    },
    {
        title: 'ChatGPT Al Voice Chatbot Build with React and FAST API Combo',
        institution: 'Údemy',
        url: 'https://www.udemy.com/certificate/UC-d60f6303-9d9f-4e75-8cc5-b12eafdbd24d/',
        urlDownload: `${urlCertificate}ChatGPT AI Voice Chatbot.pdf`,
        img: `${urlImg}ChatGPT AI Voice Chatbot.jpg`,
    },
    {
        title: 'NestJS + Microservices: Scalable and Modular Applications',
        institution: 'DevTalles',
        url: 'https://cursos.devtalles.com/certificates/ay3uhgpfdj',
        urlDownload: `${urlCertificate}NestJS + Microservicios.pdf`,
        img: `${urlImg}NestJS + Microservicios.jpg`,
    },
    {
        title: 'Second place in the scientific poster contest in the research category',
        institution: 'ESPE',
        urlView: `${urlCertificate}Certificado de investigacion.pdf`,
        urlDownload: `${urlCertificate}Certificado de investigacion.pdf`,
        img: `${urlImg}Certificado de investigacion.jpg`,
    },
];
