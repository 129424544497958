import React, { useEffect, useState } from 'react';
import './Portfolio.scss';
import ScrollToTop from '../../components/content/scrollTop/ScrollToTop';
import Categories from '../../components/content/filter_categories/Categories';
import Search from '../../components/content/search/Search';
import CardProject from '../../components/content/cards/card_projects/CardProject';
import Paginator from '../../components/content/paginator/Paginator';
import { cardsData, categories } from './data';

export default function Portfolio({ setPageTitle, isSidebarExpanded }) {

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Numero de targetas
  let cardsPerPage = 8;
  const whithPage = document.documentElement.clientWidth;

  if (whithPage <= 660 && isSidebarExpanded === true) {
    cardsPerPage = 5;
  } else if (whithPage <= 768 && isSidebarExpanded === true) {
    cardsPerPage = 6;
  } else if (whithPage <= 900 && isSidebarExpanded === true) {
    cardsPerPage = 5;
  } else if (whithPage <= 1170 && isSidebarExpanded === true) {
    cardsPerPage = 6;
  } else if (whithPage <= 1400 && isSidebarExpanded === true) {
    cardsPerPage = 6;
  } else if (isSidebarExpanded === true) {
    cardsPerPage = 8;
  }

  if (whithPage <= 630 && isSidebarExpanded === false) {
    cardsPerPage = 5;
  } else if (whithPage <= 950 && isSidebarExpanded === false) {
    cardsPerPage = 6;
  } else if (whithPage <= 1200 && isSidebarExpanded === false) {
    cardsPerPage = 6;
  } else if (whithPage <= 1470 && isSidebarExpanded === false) {
    cardsPerPage = 8;
  } else if (isSidebarExpanded === false) {
    cardsPerPage = 10;
  }


  useEffect(() => {
    setPageTitle('Portfolio');
  }, [setPageTitle]);

  const handleCategoryClick = (category) => {
    // Seleccion de categorias
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(category)
        ? prevSelected.filter((cat) => cat !== category)
        : [...prevSelected, category]
    );
    setCurrentPage(1); // Reiniciar a la primera página al cambiar de categoría
  };

  // Filtro de cards
  const filteredCards = cardsData.filter((card) => {
    // Filtrar por categoría seleccionada
    const categoryMatch = selectedCategories.length === 0 || selectedCategories.includes(card.category);

    // Filtrar por término de búsqueda en el título o en las tecnologías
    const searchTermLower = searchTerm.toLowerCase();
    const titleMatch = card.title.toLowerCase().includes(searchTermLower);

    // Verifica si alguna de las tecnologías incluye el término de búsqueda
    const techMatch = card.tecnologies.some(tech =>
      tech.name.toLowerCase().includes(searchTermLower)
    );

    return categoryMatch && (titleMatch || techMatch);
  });

  // Paginación
  const totalPages = Math.ceil(filteredCards.length / cardsPerPage);
  const currentCards = filteredCards.slice((currentPage - 1) * cardsPerPage, currentPage * cardsPerPage);
  const currentCount = currentCards.length;

  return (
    <section className='content_portfolio'>
      {/* Redireccionar hacia el inicio de la ventana */}
      <ScrollToTop />

      {/* Selector de categorias */}
      <Categories categories={categories} selectedCategories={selectedCategories} handleCategoryClick={handleCategoryClick} />

      {/* Buscador de proyectos */}
      <Search placeholderText='Search project ...' searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      {/* Contador de tarjetas visibles */}
      <div className='cards_counter'>
        <i className="fa-solid fa-objects-column"></i>
        <span>Showing <b>{currentCount}</b> of <b>{filteredCards.length}</b> projects</span>
      </div>

      {/* Tarjetas de proyectos */}
      <CardProject isSidebarExpanded={isSidebarExpanded} currentCards={currentCards} />

      {/* Paginador */}
      <Paginator currentPage={currentPage} totalPages={totalPages} cardsPerPage={cardsPerPage} setCurrentPage={setCurrentPage} />

    </section>
  );
}
