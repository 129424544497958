import React, { useState, useRef, useLayoutEffect, ReactElement } from 'react';
import ReactDOM from 'react-dom';
import './Tooltip.scss';

export default function Tooltip({ text = 'Default', position = 'top', status = true, conftX = 0, conftY = 0, children, translateText }) {
    const [visible, setVisible] = useState(false);
    const [coords, setCoords] = useState({});
    const [tooltipSize, setTooltipSize] = useState({ width: 0, height: 0 });
    const [currentPosition, setCurrentPosition] = useState(position); // Controlar la posición del tooltip dinámicamente
    const [isTouchDevice, setIsTouchDevice] = useState(false); // Controlar si el dispositivo es táctil
    const tooltipRef = useRef(null);

    // Detectar si es un dispositivo táctil
    useLayoutEffect(() => {
        setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
    }, []);

    // Mostrar el contenido
    const handleShowTooltip = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        setCoords({
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX,
            width: rect.width,
            height: rect.height,
        });
        setVisible(true);
    };

    // Ocultar el contenido
    const handleHideTooltip = () => {
        setVisible(false);
    };

    useLayoutEffect(() => {
        if (visible && tooltipRef.current) {
            const tooltipRect = tooltipRef.current.getBoundingClientRect();

            // Ajustar posición si el tooltip se sale del viewport
            if (tooltipRect.right > window.innerWidth) {
                setCurrentPosition('left');
            } else if (tooltipRect.left < 0) {
                setCurrentPosition('right');
            }

            if (tooltipRect.bottom > window.innerHeight) {
                setCurrentPosition('top');
            } else if (tooltipRect.top < 0) {
                setCurrentPosition('bottom');
            }

            setTooltipSize({
                width: tooltipRect.width,
                height: tooltipRect.height,
            });
        }
    }, [visible, coords, currentPosition]);

    // Obtener y asignar la posicion en la que se mostrara en base al elemento en donde se aplica
    const renderTooltip = () => {
        if (!visible || !status) return null;

        let style = {};

        switch (currentPosition) {
            case 'top':
                style = {
                    left: coords.left + coords.width / 2 - tooltipSize.width / 2 + conftX,
                    top: coords.top - tooltipSize.height - 8 + conftY,
                };
                break;
            case 'right':
                style = {
                    left: coords.left + coords.width + 10 + conftX,
                    top: coords.top + coords.height / 2 - tooltipSize.height / 2 + conftY,
                };
                break;
            case 'bottom':
                style = {
                    left: coords.left + coords.width / 2 - tooltipSize.width / 2 + conftX,
                    top: coords.top + coords.height + 8 + conftY,
                };
                break;
            case 'left':
                style = {
                    left: coords.left - tooltipSize.width - 10 + conftX,
                    top: coords.top + coords.height / 2 - tooltipSize.height / 2 + conftY,
                };
                break;
            default:
                style = {
                    left: coords.left + coords.width / 2 - tooltipSize.width / 2 + conftX,
                    top: coords.top - tooltipSize.height - 8 + conftY,
                };
        }

        return ReactDOM.createPortal(
            <div
                ref={tooltipRef}
                className={`tooltip tooltip-${currentPosition} ${text ? null : 'invisible'}`}
                style={style}
                translate={translateText ? 'no' : undefined}
            >
                {text}
            </div>,
            document.body
        );
    };


    const tooltipChildren = React.Children.map(children, (child) => {
        if (React.isValidElement(child) && typeof child !== 'string') {
            const eventHandlers = isTouchDevice
                ? {
                    onTouchStart: handleShowTooltip,
                    onTouchEnd: handleHideTooltip,
                }
                : {
                    onMouseEnter: handleShowTooltip,
                    onMouseLeave: handleHideTooltip,
                };

            return React.cloneElement(child, eventHandlers);
        }
        return child;
    });


    return (
        <>
            {tooltipChildren}
            {renderTooltip()}
        </>
    );
}
