import React from 'react'
import './Paginator.scss'

export default function Paginator({ currentPage, totalPages, setCurrentPage }) {

    // Siguiente pagina
    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    // Anterior pagina
    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    return (
        <div className='pagination'>
            
            {/* Texto */}
            <span className='text'>Page
                <span translate="no">{totalPages === 0 ? 0 : currentPage}</span>
                of
                <span translate="no">{totalPages}</span>
            </span>

            {/* Botones */}
            <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                <i className="fa-solid fa-circle-left"></i>
            </button>

            <button onClick={goToNextPage} disabled={currentPage === totalPages || totalPages === 0}>
                <i className="fa-solid fa-circle-right"></i>
            </button>
        </div>
    )
}
