import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { clients, competencies } from './data'
import './About.scss'
import ScrollToTop from '../../components/content/scrollTop/ScrollToTop';
import CardCompetencies from '../../components/content/cards/card_competencies/CardCompetencies'
import CarrouselImg from '../../components/content/carrousels/carrousel_img/CarrouselImg'

export default function About({ setPageTitle, isSidebarExpanded }) {

  useEffect(() => {
    setPageTitle('About');
  }, [setPageTitle]);

  return (
    <section className='content_about'>
      {/* Redireccionar hacia el inicio de la ventana */}
      <ScrollToTop />

      {/* Informacion sobre mi */}
      <div className='about code'>
        <p className='text'>In my personal career I have always been fascinated by <b>creating</b> and <b>building</b> new things and the world of development has allowed me to get into this <b>personal vision</b> polishing <b>skills</b> in design, organization and problem solving by applying logical reasoning.</p>

        <p className='text'>In my years of <b>academic training</b> I have strengthened my sense of leadership, teamwork, decision making, knowledge about development technologies, responsibility and most importantly, the value of our <b>colleagues</b> and <b>friends</b>.</p>

        <p className='text'>This is my <b>web portfolio</b> where I highlight my progress as a <b>programmer</b> and the most relevant work I have done so far. You can send me your recommendations of the site <Link className='link' to="/contact"><u>here</u></Link> to keep improving. Thanks for your time.</p>
      </div>

      {/* Competencias */}
      <CardCompetencies isSidebarExpanded={isSidebarExpanded} title="My competences" data={competencies} />

      {/* Imagenes de clientes */}
      <CarrouselImg isSidebarExpanded={isSidebarExpanded} title="Clients" data={clients} />
    </section>
  )
}
