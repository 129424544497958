import React, { setStatus } from 'react'
import './CardMessage.scss';

export default function CardMessage() {

    const CardMessage = ({ url, icon, label }) => {
        return (
            <a className='card' href={url} target="_blank" rel="noopener noreferrer" >
                <i className={`fa-brands ${icon}`}></i>
                {label && <span className="label">{label}</span>}
            </a>
        )
    }

    return (
        <div className='cards_message'>
            <h2 className='subtitle'>Chats</h2>
            <div className='cards'>
                <CardMessage url={"https://wa.me/+593963188745"} icon={'fa-whatsapp'} label={'Send Message to WhatssApp'} />
                <CardMessage url={"https://m.me/100059462091385"} icon={'fa-facebook-messenger'} label={'Send Message to Messenger'} />
            </div>
        </div>
    )
}
