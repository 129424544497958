import React from 'react'
import './CarrouselCertificates.scss'
import Tooltip from '../../tooltip/Tooltip'

export default function CardCertificates({ isSidebarExpanded, title = 'Default title', data }) {

    const Certificate = ({ certificate }) => {
        return (
            <>
                <div className='img_content'>
                    {/* Imagen del certificado */}
                    <img className='image' src={certificate.img} alt={certificate.title} />

                    {/* Botones de accion */}
                    <div className='buttons'>
                        <Tooltip text='View' position='top' className='tooltip'>
                            {certificate.urlView && <a href={certificate.urlView} target='_blank' rel="noopener noreferrer"><i className="fa-solid fa-eye"></i></a>}
                        </Tooltip>

                        <Tooltip text='Open' position='top' className='tooltip'>
                            {certificate.url && <a href={certificate.url} target='_blank' rel="noopener noreferrer"><i className="fa-solid fa-globe"></i></a>}
                        </Tooltip>

                        <Tooltip text='Download' position='top' className='tooltip'>
                            {certificate.urlDownload && <a href={certificate.urlDownload} target='_blank' rel="noopener noreferrer" download><i className="fa-solid fa-cloud-arrow-down"></i></a>}
                        </Tooltip>
                    </div>
                </div>

                {/* Titulo y descipcion */}
                <div className='text'>
                    {certificate.title && <h3 className='title'>{certificate.title}</h3>}
                    {certificate.description && <span className='description'>{certificate.description}</span>}
                </div>

                {/* Nombre de la institucion */}
                {certificate.institution && <span className='institution'>{certificate.institution}</span>}
            </>
        )
    }

    return (
        <div className='content_certificate'>
            <h2 className='subtitle'>{title}</h2>

            <div className={`card_certificate scroll_element ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
                {data
                    .map((certificate, index) => ({ ...certificate, index })) // Agregamos el índice como propiedad en cada elemento
                    .sort((a, b) => b.index - a.index) // Ordenamos de forma descendente por el índice
                    .map((certificate) => (
                        <div className={`certificates`} key={certificate.id || certificate.index}>
                            <Certificate certificate={certificate} />
                        </div>
                    ))}
            </div>
        </div>
    )
}
