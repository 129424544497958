import React, { useState, useEffect } from 'react';
import './FullScreenGallery.scss';

export default function FullScreenGallery({ images, onClose }) {

    // Indicador de imagen
    const [currentIndex, setCurrentIndex] = useState(0);
   
    // Estado para controlar la carga de la imagen
    const [loading, setLoading] = useState(true); 

    // Volver a poner el indicador cuando se cambia de imagen
    const handleNext = () => {
        setLoading(true); 
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    // Volver a poner el indicador cuando se cambia de imagen
    const handlePrev = () => {
        setLoading(true); 
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    // Eventos del teclado
    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            onClose();
        } else if (event.key === 'ArrowRight') {
            handleNext();
        } else if (event.key === 'ArrowLeft') {
            handlePrev();
        }
    };

    useEffect(() => {
        // Agrega el evento para detectar cuando se presiona una tecla
        window.addEventListener('keydown', handleKeyDown);

        // Limpia el evento cuando el componente se desmonta
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // Manejar cuando la imagen se haya cargado
    const handleImageLoaded = () => {
        setLoading(false);
    };

    return (
        <div className="fullscreen-gallery">
            {/* Cerrar */}
            <button className="close-btn" onClick={onClose}><i className="fa-solid fa-xmark"></i></button>

            {/* Mostrar el indicador de carga si la imagen no está lista */}
            {loading && <div className="loading-indicator">Cargando...</div>}

            {/* Imagen que muestra el evento de carga */}
            <img
                src={images[currentIndex]}
                alt={`Image ${currentIndex + 1}`}
                className="gallery-image"
                onLoad={handleImageLoaded} // Evento que se dispara cuando la imagen se carga
                style={{ display: loading ? 'none' : 'block' }} // Ocultar la imagen mientras se carga
            />

            {/* Direcionales de imagen */}
            {images.length !== 1 &&
                <>
                    <button className="prev-btn" onClick={handlePrev}><i className="fa-solid fa-circle-arrow-left"></i></button>
                    <button className="next-btn" onClick={handleNext}><i className="fa-solid fa-circle-arrow-right"></i></button>
                </>
            }
        </div>
    );
}
