import React from 'react'
import Tooltip from '../../../content/tooltip/Tooltip'
import './_social_links.scss'

export default function SocialLinks({ isExpanded }) {

    const SocialLink = ({ name, url, icon }) => {
        return (
            <Tooltip translateText={true} text={name} position={isExpanded ? 'top' : 'right'}>
                <a href={url} className="social-link" target='_blank'>
                    <i className={`fa-brands ${icon}`}></i>
                </a>
            </Tooltip>
        )
    }

    return (
        <div className='container_social'>
            <hr className='divisor'></hr>
            <div className='social-links'>
                <SocialLink name='LinkedIn' url='https://www.linkedin.com/in/armando-josue-velasquez-delgado-354399178/' icon='fa-linkedin' />
                <SocialLink name='GitHub' url='https://github.com/Armando-Velasquez' icon='fa-github' />
                <SocialLink name='Facebook' url='https://www.facebook.com/people/A-Josue-Velasquez/pfbid0ABWRpfkopsXfQ7jx8wjyvQLk3SUC7G2yrSaGkjwVVVgxh5j3iKC7JuYeJaa1j9XYl/' icon='fa-facebook-f' />
                <SocialLink name='Instagram' url='https://www.instagram.com/josue.velasquez_ad?igsh=emdydzIxeXhjbnJl' icon='fa-instagram' />
            </div>
        </div>
    )
}
