import React from 'react'
import { Routes, Route } from 'react-router-dom';

// Importar los componentes de paginas
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Portfolio from './pages/portfolio/Portfolio';
import Resume from './pages/resume/Resume';
import Err404 from './pages/error/404/Err404';

export default function AppRoutes({ setNavbarVisible, setSidebarVisible, isSidebarExpanded, setPageTitle }) {
  return (
    <Routes>
      {/* Rutas válidas donde el Navbar está visible */}
      <Route path="/" element={
        <Home
          setPageTitle={setPageTitle}
          setNavbarVisible={setNavbarVisible}
          setSidebarVisible={setSidebarVisible}
          isSidebarExpanded={isSidebarExpanded}
        />
      } />

      <Route path="/about" element={
        <About
          setPageTitle={setPageTitle}
          setNavbarVisible={setNavbarVisible}
          setSidebarVisible={setSidebarVisible}
          isSidebarExpanded={isSidebarExpanded}
        />
      } />

      <Route path="/resume" element={
        <Resume
          setPageTitle={setPageTitle}
          setNavbarVisible={setNavbarVisible}
          setSidebarVisible={setSidebarVisible}
          isSidebarExpanded={isSidebarExpanded}
        />
      } />

      <Route path="/portfolio" element={
        <Portfolio
          setPageTitle={setPageTitle}
          setNavbarVisible={setNavbarVisible}
          setSidebarVisible={setSidebarVisible}
          isSidebarExpanded={isSidebarExpanded}
        />
      } />

      <Route path="/contact" element={
        <Contact
          setPageTitle={setPageTitle}
          setNavbarVisible={setNavbarVisible}
          setSidebarVisible={setSidebarVisible}
        />
      } />

      {/* Ruta de error 404 sin mostrar el Navbar */}
      <Route path="*" element={
        <Err404
          setPageTitle={setPageTitle}
          setNavbarVisible={setNavbarVisible}
          setSidebarVisible={setSidebarVisible}
        />
      } />
    </Routes>
  )
}
