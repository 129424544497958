import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import './Err404.scss';

export default function Err404({ setNavbarVisible, setSidebarVisible, setPageTitle }) {

  useEffect(() => {
    setPageTitle('');

    // Oculta el Navbar o Sidebar cuando se monta el componente
    setNavbarVisible(false);
    setSidebarVisible(false);

    // Asegura que el Navbar o Sidebar vuelva a aparecer si salimos de la página 404
    return () => {
      setNavbarVisible(true);
      setSidebarVisible(true);
    };
  }, [setNavbarVisible, setSidebarVisible, setPageTitle]);

  return (
    <section className="content_error">

      <span className="title">404</span>
      <span className="subtitle">Page not found</span>

      <div className="content_button">
        <Link to="/" className="button"> Go to home</Link>
      </div>

    </section>
  )
}
