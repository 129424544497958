import React, { useEffect } from 'react';
import './Home.scss';
import ScrollToTop from '../../components/content/scrollTop/ScrollToTop';
import Initials from '../../components/content/initials/Initials';

export default function Home({ isSidebarExpanded, setPageTitle }) {

    const avatar = `${process.env.REACT_APP_NAME_APP}/assets/img/photo/avatar.png`;

    useEffect(() => {
        setPageTitle('');
    }, [setPageTitle]);

    return (
        <section className={`content_home`}>
            <ScrollToTop />

            {/* Renderizamos el SVG con animación */}
            <Initials isSidebarExpanded={isSidebarExpanded} />

            {/* Contenido de la sección de nombre */}
            <div className='greeting'>
                <p>Hi, I'm</p>
                <h1 translate='no'>ARMANDO JOSUE VELASQUEZ DELGADO</h1>
                <p>
                    <i className="fa-regular fa-arrow-right-long fa-fade arrow"></i> <i>IT Engineer</i>
                </p>
            </div>

            {/* Contenido de la seccion de la descipcion y foto */}
            <div className='content'>
                <div className='message code'>
                    <p>I have experience in both <b><u>back-end</u></b> and <b><u>front-end</u></b> application development, I enjoy working in <i>multidisciplinary teams</i> to turn complex challenges into <b>innovative solutions</b>.</p>
                </div>

                <div className='content_photo'>
                    <div className='photo'>
                        <img src={avatar} alt='Profile' />
                    </div>
                </div>
            </div>

        </section>
    );
}
