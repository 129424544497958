import React, { useEffect, useRef } from 'react';
import './Timeline.scss';

const Timeline = ({ icon = 'fa-icons', title = 'Default title', data }) => {
    const refs = useRef([]);

    useEffect(() => {
        
        // Aplicacion de efecto
        refs.current.forEach((node) => {
            if (node) {
                const observer = new IntersectionObserver(
                    ([entry]) => {
                        if (entry.isIntersecting) {
                            node.classList.add('animated');
                            observer.unobserve(node);
                        }
                    },
                    { threshold: 0.1 }
                );
                observer.observe(node);
            }
        });

        return () => {
            refs.current.forEach((node) => {
                if (node) {
                    const observer = new IntersectionObserver(() => { });
                    observer.unobserve(node);
                }
            });
        };
    }, [data]);

    return (
        <section className="timeline">
            
            {/* Titulo */}
            <div className="title-wrapper">
                <div className="icon-box">
                    <i className={`fa-solid ${icon}`}></i>
                </div>
                <h3>{title}</h3>
            </div>

            {/* Informacion */}
            <ul className="list">
                {data
                    .map((element, index) => ({ ...element, index }))
                    .sort((a, b) => b.index - a.index)
                    .map((element, index) => (
                        <li
                            className="item"
                            key={index}
                            ref={(el) => (refs.current[index] = el)}
                        >
                            {/* Contenido */}
                            <div className="content">
                                <h4 className="title">{element.title}</h4>
                                <p className="date">{element.date}</p>

                                {/* Iteramos sobre el array de subtítulos y textos */}
                                {element.sections && element.sections.map((section, secIndex) => (
                                    <div className='info' key={secIndex}>
                                        {section.subtitle && <h2 className='subtitle'>{section.subtitle}</h2>}
                                        <p className="text">{section.text}</p>
                                    </div>
                                ))}
                            </div>
                        </li>
                    ))}
            </ul>
        </section>
    );
};

export default Timeline;
