import React, { useEffect } from 'react'
import './Resume.scss'
import ScrollToTop from '../../components/content/scrollTop/ScrollToTop';
import { educations, experiences, workCertifications, certifications } from './data';
import Timeline from '../../components/content/timeline/Timeline';
import ProgressBar from '../../components/content/progress_bar/ProgressBar';
import CardCertificates from '../../components/content/carrousels/carrousel_certificates/CarrouselCertificates';
import CardImg from '../../components/content/cards/card_imgs/CardImg';

export default function Resume({ setPageTitle, isSidebarExpanded }) {

  useEffect(() => {
    setPageTitle('Resume');
  }, [setPageTitle]);

  const urlIcon = 'assets/icons/tecnologies/';

  return (
    <section className={`content_resume ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
      {/* Redireccionar hacia el inicio de la ventana */}
      <ScrollToTop />

      {/* Informacion academica y habilidades */}
      <div className='section_info'>
        <div className='timeline_content'>
          <Timeline title="Education" icon="fa-book-open-cover" data={educations} />
          <Timeline title="Experience" icon="fa-briefcase" data={experiences} />
        </div>

        <div className='skill_content'>
          <h2 className='subtitle'>Main tecnologies</h2>

          <div className='technical_knowladge'>
            <h3 className='title'>Technical Knowledge</h3>
            <ProgressBar icon={`${urlIcon}angular.svg`} name='Angular' percentage={85} color='DD0031' translateText={true} />
            <ProgressBar icon={`${urlIcon}react.svg`} name='React' percentage={50} color='53C1DE' translateText={true} />
            <ProgressBar icon={`${urlIcon}nodejs.svg`} name='NodeJS' percentage={90} color='8CC84B' translateText={true} />
            <ProgressBar icon={`${urlIcon}python.svg`} name='Python' percentage={75} color='387eb8' translateText={true} />
            <ProgressBar icon={`${urlIcon}springboot.svg`} name='SpringBoot' percentage={70} color='70AD51' translateText={true} />
            <ProgressBar icon={`${urlIcon}php.svg`} name='PHP' percentage={90} color='777BB3' translateText={true} />
            <ProgressBar icon={`${urlIcon}javascript.svg`} name='JavaScript' percentage={80} color='ffca28' translateText={true} />
            <ProgressBar icon={`${urlIcon}java.svg`} name='Java' percentage={50} color='5382A1' translateText={true} />
            <ProgressBar icon={`${urlIcon}kotlin.svg`} name='Kotlin' percentage={55} color='a97bff' translateText={true} />
            <ProgressBar icon={`${urlIcon}flutter.svg`} name='Flutter' percentage={50} color='1fbcfd' translateText={true} />
            <ProgressBar icon={`${urlIcon}wordpress.svg`} name='WordPress' percentage={95} color='0d61a9' translateText={true} />
            <ProgressBar icon={`${urlIcon}puntonet.svg`} name='.Net' percentage={40} color='1969bc' translateText={true} />
          </div>

          <div className='databases_content'>
            <h3 className='title'>Databases</h3>
            <ProgressBar icon={`${urlIcon}mysql.svg`} name='MySQL' percentage={90} color='5d87a1' translateText={true} />
            <ProgressBar icon={`${urlIcon}sqlserver.svg`} name='SQL Server' percentage={80} color='b71c1c' translateText={true} />
            <ProgressBar icon={`${urlIcon}postgre.svg`} name='PostgreSQL' percentage={70} color='336791' translateText={true} />
            <ProgressBar icon={`${urlIcon}mongo.svg`} name='MongoDB' percentage={80} color='13aa52' translateText={true} />
            <ProgressBar icon={`${urlIcon}firebase.svg`} name='Firebase' percentage={60} color='EEAB37' translateText={true} />
          </div>

          <div className='others_tecnologies'>
            <h3 className='title'>Development Tools</h3>
            <div className='icons'>
              <CardImg url={`${urlIcon}hostinger_letters.svg`} />
              <CardImg url={`${urlIcon}amazonwebservices_letters.svg`} />
              <CardImg url={`${urlIcon}googleCloud_letters.svg`} />
              <CardImg url={`${urlIcon}vscode_letters.svg`} />
              <CardImg url={`${urlIcon}visualstudio_letters.svg`} />
              <CardImg url={`${urlIcon}intellijidea_letters.svg`} />
              <CardImg url={`${urlIcon}androidstudio_letters.svg`} />
              <CardImg url={`${urlIcon}eclipse_letters.svg`} />
              <CardImg url={`${urlIcon}postman_letters.svg`} />
              <CardImg url={`${urlIcon}googlecolab_letters.svg`} />
              <CardImg url={`${urlIcon}jupyter.svg`} />
              <CardImg url={`${urlIcon}anaconda_letters.svg`} />
              <CardImg url={`${urlIcon}orange.svg`} />
              <CardImg url={`${urlIcon}blender_letters.svg`} />
            </div>
          </div>

        </div>
      </div>

      {/* Certificados de trabajo */}
      <CardCertificates isSidebarExpanded={isSidebarExpanded} title='Employment Certificate' data={workCertifications} />

      {/* Certificados de cursos */}
      <CardCertificates isSidebarExpanded={isSidebarExpanded} title='Certificate of Completion' data={certifications} />
    </section>
  )
}
