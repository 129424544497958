// Datos de clientes
export const clients = [
    {
        src: 'assets/img/clients/logo_UEE.png',
        alt: 'Logo UEE',
        url: 'https://www.facebook.com/unidadeducativasd2'
    },
    {
        src: 'assets/img/clients/logo_GRUPO_HTC.png',
        alt: 'Logo HTC',
        url: 'https://www.facebook.com/hingelectc/?locale=es_LA'
    },
    {
        src: 'assets/img/clients/logo_Sciedtec.png',
        alt: 'Logo Sciedtec',
        url: 'https://sciedtec.com'
    },
    {
        src: 'assets/img/clients/logo_JuntaAgua.png',
        alt: 'Logo Junta de agua'
    },
    {
        src: 'assets/img/clients/logo_Iberoamericano.png',
        alt: 'Logo Iberoamericano',
        url: 'https://www.facebook.com/colegioparticulariberoamericano/?locale=es_LA'
    },
    {
        src: 'assets/img/clients/logo_BoostAntares.png',
        alt: 'Logo BoostAntares',
        url: 'https://boostantares.com'
    },
    {
        src: 'assets/img/clients/logo_Crearte.png',
        alt: 'Logo Crearte',
        url: 'https://www.facebook.com/createagenciapublicitaria'
    },
];

// Datos de competencias
export const competencies = [
    {
        icon: 'fa-display-code',
        title: 'Web Design',
        description: 'I have experience in creating attractive and functional interfaces that offer an excellent user experience. I specialize in responsive design and always look to follow the latest UI/UX design trends.',
        percentage: 80,
    },
    {
        icon: 'fa-rectangle-terminal',
        title: 'Web Developer',
        description: 'I develop efficient and scalable web applications using modern technologies. I am comfortable working with frameworks like React and Angular, optimizing performance and ensuring the best functionality.',
        percentage: 80,
    },
    {
        icon: 'fa-brain-circuit',
        title: 'Artificial Intelligence',
        description: 'I am passionate about AI, with experience in creating machine learning models and solutions that use artificial intelligence for problem solving.',
        percentage: 70,
    },
    {
        icon: 'fa-chart-pie',
        title: 'Data Analysis',
        description: 'I analyze large volumes of data to obtain valuable information. My ability to visualize data allows me to communicate findings in a clear and understandable way.',
        percentage: 65,
    },
    {
        icon: 'fa-draw-polygon',
        title: 'Graphic Design',
        description: 'I combine creativity and technique to design stunning visual materials. My focus is on brand consistency and aesthetics, using tools such as Adobe Photoshop and Illustrator.',
        percentage: 50,
    },
    {
        icon: 'fa-cube',
        title: '3D Modeling',
        description: 'I have experience in 3D modeling, creating designs and prototypes for visual projects. I specialize in tools like Blender.',
        percentage: 50,
    },
]
