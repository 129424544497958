import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './Form.scss';
import AlertText from '../alert/alert_text/AlertText';

export default function Form() {

    // Valores del formulario por defecto
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        message: '',
    });

    // Asignacion de estados
    const [errors, setErrors] = useState({});

    // Variables para el alert
    const [icon, setIcon] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [isLoad, setIsLoad] = useState(false);

    // Validar los campos
    const validate = () => {
        const newErrors = {};

        if (!formData.fullName) {
            newErrors.fullName = 'Full name is required';
        }
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email address is invalid';
        }
        if (!formData.message) {
            newErrors.message = 'Message is required';
        }

        return newErrors;
    };

    // Cambio de icono con animacion de de carga
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Limpiar el error solo para el campo que está siendo modificado
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ''  // Limpiar el error del campo actual
        }));

        // Actualizar el valor del campo
        setFormData({ ...formData, [name]: value });
    };

    // Envio del formulario
    const handleSubmit = (e) => {
        e.preventDefault();

        // Datos que se envian
        const templateParams = {
            from_name: formData.fullName,
            email: formData.email,
            message: formData.message,
            to_name: 'Josue Velasquez' // Opcional, tu nombre o quien recibe el correo
        };

        // Limpiar mensaje de éxito previo
        setIcon('');
        setTitle('');
        setMessage('');

        // Validar los campos antes de enviar
        const validationErrors = validate();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {

            // Indicar que se está enviando el formulario
            setIsLoad(true);

            // Si no hay errores, enviar el correo con EmailJS
            emailjs.send(
                process.env.REACT_APP_ID_SERVICE,  // ID de servicio
                process.env.REACT_APP_ID_TEMPLATE,  // ID de plantilla
                templateParams,
                process.env.REACT_APP_ID_USER       // ID de usuario de EmailJS
            ).then((result) => {

                // Datos de la alerta
                setIcon('fa-envelopes-bulk');
                setTitle('Message sent successfully!');
                setMessage('Thank you for your message, you will receive a reply shortly.');

                setFormData({
                    fullName: '',
                    email: '',
                    message: ''
                });

                // Indicar que finalizó el envío del formulario
                setIsLoad(false);

            }, (error) => {

                // Datos de la alerta
                setIcon('fa-envelopes-bulk');
                setTitle('Failed to send message');
                setMessage('It was not possible to send your message, I tried another time.');

                // Indicar que finalizó el envío del formulario
                setIsLoad(false);
            });
        }
    };

    return (
        <>
            <form className='form_contact' onSubmit={handleSubmit} noValidate>
                <h2 className='subtitle'>Send Email</h2>
                <div className='info'>
                    <div className='address'>

                        {/* Input de nombre */}
                        <div className='element'>
                            <input
                                className='input'
                                type='text'
                                name='fullName'
                                placeholder='Full name'
                                value={formData.fullName}
                                onChange={handleInputChange}
                            />
                            {errors.fullName && <span className='error'>{errors.fullName}</span>}
                        </div>

                        {/* Input de correo */}
                        <div className='element'>
                            <input
                                className='input'
                                type='email'
                                name='email'
                                placeholder='Email address'
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            {errors.email && <span className='error'>{errors.email}</span>}
                        </div>
                    </div>

                    {/* Imput de mensaje */}
                    <div className='element message'>
                        <textarea
                            className='input textarea scroll_element'
                            name='message'
                            placeholder='Your message ...'
                            value={formData.message}
                            onChange={handleInputChange}
                        ></textarea>
                        {errors.message && <span className='error'>{errors.message}</span>}
                    </div>

                    {/* Boton de envio */}
                    <div className='buttons'>
                        <button className='button1' type='submit'>

                            {isLoad && <img className='image_load' src="assets/gif/load2.gif" ></img>}
                            {!isLoad && <i className="fa-solid fa-paper-plane"></i>}

                            <span>Send Message</span>
                        </button>
                    </div>

                    {/* Alerta */}
                    {message &&
                        <AlertText icon={icon} title={title} message={message} setMessage={setMessage} />
                    }
                </div>
            </form>
        </>
    )
}
