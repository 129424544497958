import React from 'react'

export default function Search({ placeholderText, searchTerm, setSearchTerm }) {
    return (
        <input
            type='text'
            placeholder={placeholderText}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className='input'
        />
    )
}
