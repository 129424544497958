import React from 'react'
import './AlertText.scss';

export default function AlertText({ icon, title, message, setMessage, buttons }) {

    // Cierre de la alerta
    const handdleButton = () => {
        setMessage('')
    }

    return (
        <>
            <div className='overflow'></div>
            <div className='alert-successxx'>

                {/* Boton de cierre de alerta */}
                <button onClick={handdleButton} className='close'>
                    <i className="fa-solid fa-xmark"></i>
                </button>

                {/* Icono de alerta */}
                <div className='icon'>
                    <i className={`fa-solid ${icon}`}></i>
                </div>

                <h2 className='title'>{title}</h2>

                <p className='messaje'>{message}</p>

                {/* Botones de accion */}
                {buttons && <div className='buttons'>
                    {buttons.btn1name && <button className='button1' onClick={buttons.btn1Action}>
                        <span>{buttons.btn1name}</span>
                    </button>}

                    {buttons.btn2name && <button className='button1' onClick={buttons.btn2Action}>
                        <span>{buttons.btn2name}</span>
                    </button>}
                </div>}
            </div>
        </>
    )
}
