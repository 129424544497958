import React, { useState } from 'react';
import Tooltip from '../../tooltip/Tooltip';
import './CardProject.scss';
import FullScreenGallery from '../../gallery/FullScreenGallery';
import Initials from '../../initials/Initials';

export default function CardProject({ isSidebarExpanded, currentCards }) {

    // Galeria
    const [showGallery, setShowGallery] = useState(false);
    const [galleryImages, setGalleryImages] = useState([]);

    // Accion para mostrar las imagenes
    const handleImageClick = (images) => {
        setGalleryImages(images);
        setShowGallery(true);
    };

    // Accion de cerrar galeria
    const handleCloseGallery = () => {
        setShowGallery(false);
        setGalleryImages([]);
    };

    return (
        <>
            {/* Vista de la galeria */}
            {showGallery && (
                <FullScreenGallery images={galleryImages} onClose={handleCloseGallery} />
            )}

            {currentCards.length > 0 ? (
                <div className={`cards_container ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
                    {currentCards.map((card) => (
                        <div key={card.id} className='card'>
                            <div className='image_content'>
                                <div className='picture'>
                                    <Tooltip text={card.icon_name}>
                                        {card.isGallery ? (
                                            // Si no hay un enlace, muestra la imagen en pantalla completa
                                            <a className={`icon_picture ${card.isGallery ? 'selector' : ''}`} onClick={() => handleImageClick(card.images || [card.img])}>
                                                <i className={card.icon_img}></i>
                                            </a>
                                        ) : (
                                            // Si hay un enlace, abre el enlace
                                            <a href={card.url} className='icon_picture' target='_blank' rel="noopener noreferrer">
                                                <i className={card.icon_img}></i>
                                            </a>
                                        )}
                                    </Tooltip>

                                    {/* Activacion de imagen de la aplicacion por defecto */}
                                    {card.activeInitials && <Initials />}
                                    <img className={`image_picture ${card.activeInitials ? 'image_picture_defect' : null}`} src={card.img} alt={card.title} />

                                </div>

                                {/* Boton de descarga para archivos */}
                                {card.download && (
                                    <Tooltip key={card.id + '-download'} text={card.download[1]} position='left'>
                                        <a href={card.download[2]} className='icon_download' download>
                                            <i className={card.download[0]}></i>
                                        </a>
                                    </Tooltip>
                                )}
                            </div>

                            {/* Contenido de informacion de la card */}
                            <div className='body_content'>
                                <div className='tecnologies'>
                                    <span className='text'>Made with:</span>
                                    {/* Iconos de tecnologias */}
                                    <div className='icons'>
                                        {card.tecnologies.map((tech, index) => (
                                            <Tooltip translateText={true} key={tech.name + index} text={tech.name} position="top">
                                                <img className='image' src={tech.icon} alt={tech.name} />
                                            </Tooltip>
                                        ))}
                                    </div>
                                </div>

                                <div className='text'>
                                    <span className='title'>{card.title}</span>
                                    <span className='category'>{card.category}</span>

                                    {/* Iconos adicionales */}
                                    <div className='icons_adicionals'>
                                        {card.powereds && card.powereds.map((tech, index) => (
                                            <Tooltip key={tech.name + index} text={tech.name} position={tech.position ? tech.position : 'left'}>
                                                <i className={`${tech.icon}`}></i>
                                            </Tooltip>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                // Mensaje sin resultados
                <div className='no_result'>No results found</div>
            )}
        </>
    );
}
