import React from 'react'
import './CardImg.scss'

export default function CardImg({ url }) {
    return (
        <div className='cardImage_content'>
            <img src={url} alt="Card" />
        </div>
    )
}
