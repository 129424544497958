import React from 'react';
import './CarrouselImg.scss';

export default function CarrouselImg({ isSidebarExpanded, title, data }) {
    const Card = ({ client }) => {
        return (
            <a href={client.url} className='client' target='_blank' rel="noopener noreferrer">
                <img src={client.src} alt={client.alt} className="logo" />
            </a>
        );
    };

    return (
        <div className='content_clients'>
            <h2 className='subtitle'>{title}</h2>
            <div className={`clients scroll_element ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
                {data.map((client, index) => (
                    <Card key={index} client={client} />
                ))}
            </div>
        </div>
    );
}
