import React, { useEffect } from 'react'
import './Contact.scss'
import ScrollToTop from '../../components/content/scrollTop/ScrollToTop';
import Form from '../../components/content/forms/Form';
import CardMessage from '../../components/content/cards/card_messages/CardMessage';

export default function Contact({ setPageTitle }) {

  const map = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d149914.9943758069!2d-79.25615296153846!3d-0.23673509024049227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d546535730a17d%3A0xcef17541041b9f63!2sSanto%20Domingo!5e0!3m2!1ses!2sec!4v1727993192527!5m2!1ses!2sec'

  useEffect(() => {
    // Titulo de la pagina
    setPageTitle('Contact');
  }, [setPageTitle]);

  return (
    <section className='content_contact'>
      {/* Redireccionar hacia el inicio de la ventana */}
      <ScrollToTop />

      {/* Frame de maps */}
      <div className='maps'>
        <iframe src={map} loading="lazy"></iframe>
      </div>

      <div className='contact'>

        {/* Formulario de envio */}
        <Form />

        {/* Otros contactos */}
        <CardMessage />

      </div>
    </section>
  )
}
