import React from 'react';
import './Categories.scss';

export default function Categories({ categories, selectedCategories, handleCategoryClick }) {
    return (
        <div className='category_selector'>
            {categories.map((category) => (
                <div
                    key={category.name}
                    className={`category ${selectedCategories.includes(category.name) ? 'active' : ''}`}
                    onClick={() => handleCategoryClick(category.name)}
                >
                    {category.icon && <i className={`category_icon ${category.icon}`}></i>}
                    <span>{category.name}</span>
                </div>
            ))}
        </div>
    );
}
