import React, { useState, useEffect, useRef } from 'react';
import './ProgressBar.scss';

export default function ProgressBar({ icon, name = 'Default', percentage = 0, color = 'ffffff', translateText }) {

    const [currentPercentage, setCurrentPercentage] = useState(0); // Variable del porcentaje
    const elementRef = useRef(null);  // Referencia al elemento
    const [isVisible, setIsVisible] = useState(false);  // Estado para verificar si el elemento es visible

    // Efecto para observar cuando el componente entra en el viewport
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            },
            { threshold: 0.1 }
        );

        // Observar el nodo actual
        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        // Limpiar la observación al desmontar
        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, []);

    // Efecto para incrementar el porcentaje gradualmente cuando el elemento es visible
    useEffect(() => {
        if (isVisible) {
            const increment = () => {
                setCurrentPercentage((prev) => {
                    if (prev < percentage) {
                        return prev + 1;
                    } else {
                        return percentage;
                    }
                });
            };

            const interval = setInterval(increment, 10);

            if (currentPercentage === percentage) {
                clearInterval(interval);
            }

            // Limpiar el intervalo al desmontar
            return () => clearInterval(interval);
        }
    }, [percentage, currentPercentage, isVisible]);

    return (
        <div className='element' ref={elementRef}>

            {/* Informacion */}
            <div className='text'>
                {name && <div className='name'>
                    {icon && <img className='image' src={icon} alt={name} />}
                    <span translate={translateText ? 'no' : undefined}>{name}</span>
                </div>}
                <span translate="no">{currentPercentage}%</span>
            </div>

            {/* Barra de progreso */}
            <div className='progress_bg'>
                <div className='progress_fill' style={{ width: `${currentPercentage}%`, backgroundColor: `#${color}` }}></div>
            </div>
        </div>
    );
}
